




















































import AuthMainLayout from "@/views/auth/AuthMainLayout.vue";
import { Component, Vue } from "vue-property-decorator";
import { isEmptyOrWhiteSpace, showError, showSuccess, showWarning } from "@/utils/Common";
import { MSG_SENDAUTHCODE } from "@/configs/Consts";
import { Fetcher as Ajax } from "@/utils/Request";
import { ElForm } from "element-ui/types/form";
import { MessageBox } from "element-ui";
import { API_AUTH_SEND_AUTH_CODE } from "@/configs/Apis";

@Component({
  components: { AuthMainLayout }
})
export default class Register extends Vue {
  showContract = false;
  sendingValidCode = false;
  counterNum = 60;

  userData = {
    enterpriseName: "",
    name: "",
    email: "",
    authCode: "",
    phone: "",
    loginName: "",
    loginPassword: "",
    confirmPassword: "",
    accessRules: false
  };

  get appTitle() {
    return process.env.VUE_APP_TITLE;
  }

  get rules() {
    const me = this;
    return {
      enterpriseName: [
        { required: true, message: "请输入所属单位", trigger: "blur" }
      ],
      name: { required: true, message: "请输入姓名", trigger: "blur" },
      loginName: { required: true, message: "请输入登录名", trigger: "blur" },
      loginPassword: { required: true, message: "请输入密码", trigger: "blur" },
      confirmPassword: [
        {
          required: true, message: "请输入确认密码", trigger: "blur"
        },
        {
          validator: (rule: any, value: string, callback: any) => {
            if (value !== me.userData.loginPassword) {
              callback("两次输入的密码不一致");
            } else {
              callback();
            }
          },
          trigger: "blur"
        }
      ],
      authCode: [
        { required: true, message: "请输入验证码", trigger: "blur" }
      ],
      phone: [
        { required: true, message: "请输入手机号", trigger: "blur" },
        { pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确", trigger: "blur" }
      ],
      email: [
        { required: true, message: "请输入邮箱", trigger: "blur" },
        { type: "email", message: "邮箱格式不正确", trigger: "blur" }
      ],
      accessRules: {
        validator: (rule: any, value: boolean, callback: any) => {
          if (!value) {
            callback("请接受并同意《会员服务协议》");
          } else {
            callback();
          }
        },
        trigger: "change"
      }
    };
  }

  sendValidCode() {
    const result = [/* 0,  */0];
    let pos = 0;
    (this.$refs.registerForm as any).validateField(["phone"], (message: string) => {
      if (!isEmptyOrWhiteSpace(message)) {
        return;
      }
      result[pos++] = 1;
      if (result.every(i => i > 0)) {
        // 发送验证码
        const postedParams = {
          // loginName: this.userData.loginName,
          phone: this.userData.phone
        };
        this.sendingValidCode = true;
        Ajax.queryData(API_AUTH_SEND_AUTH_CODE, postedParams, "POST", false).then(() => showSuccess(MSG_SENDAUTHCODE)).catch(({ message } = { message: "" }) => {
          this.sendingValidCode = false;
          showError(message);
        });
        this.startCountDown();
      }
    });
  }

  startCountDown() {
    const token = setInterval(() => {
      this.counterNum -= 1;
      if (this.counterNum <= 0) {
        clearInterval(token);
        this.counterNum = 30;
        this.sendingValidCode = false;
      }
    }, 1000);
  }

  submitForm() {
    (this.$refs.registerForm as ElForm)?.validate(async (isValid, invalidFields) => {
      if (!isValid) {
        const error = Object.keys(invalidFields).map(name => `<p style="margin: 5px 0">${(invalidFields as { [key: string]: Array<any> })[name][0].message}</p>`);
        return showWarning(error.join(""));
      }
      const data = {
        enterpriseName: this.userData.enterpriseName,
        name: this.userData.name,
        email: this.userData.email,
        authCode: this.userData.authCode,
        phone: this.userData.phone,
        loginName: this.userData.loginName,
        loginPassword: this.userData.loginPassword
      };
      try {
        await this.$store.dispatch("user/register", data);
        await MessageBox.alert("<font color='#32a04f'>恭喜您注册成功，请返回登录。</font>", { type: "success", dangerouslyUseHTMLString: true });
        await this.$router.push({ name: "login", query: { email: encodeURIComponent(this.userData.email) } });
      } catch (e: any) {
        await showError(e.message);
      }
    });
  }
}
